import { NgModule } from '@angular/core';
// import { Routes, RouterModule } from '@angular/router';
import {LoginAuthgardComponent} from './services/data-service/auth-gard-service'
import { Authguard1Service } from './services/data-service/authguard1.service';
import { Routes, RouterModule,PreloadAllModules} from '@angular/router';
import {QuicklinkStrategy, QuicklinkModule} from 'ngx-quicklink';
const routes: Routes = [
  {
    path:"", redirectTo:"Auth", pathMatch:"full"
  },
  {
    path:"Auth", 
    canActivateChild:[LoginAuthgardComponent],
    loadChildren: () =>import('./Auth/auth.module').then(m => m.AuthModule),
  },
  {
    path:"home", 
    canActivate:[LoginAuthgardComponent],
    loadChildren: () =>import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path:"practice",
    canActivate:[LoginAuthgardComponent],
    loadChildren: () =>import('./practice/practice.module').then(m => m.PracticeModule),
  }
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes,{preloadingStrategy: QuicklinkStrategy, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
