import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
declare var io: any;
import { environment } from '@env'


@Injectable()

export class SocketService {

    socket: any;
    componentLiten$ = new Subject<any>();
    pingTime: any;
    constructor() { }

    OnSocketConnect() {
        let socketUrl = JSON.parse(localStorage.getItem('api_baseurl')).SocketURL;
        this.socket = io.connect(socketUrl, { secure: true}
            );

        this.socket.on('connect', function (message) {
            console.log("socket connected ",new Date())
            
        });

        this.socket.on('disconnect', function (message) {
            console.log("socket is disconnected ",new Date())
            
        })
    }

    pingTimeDistory() {
        // clearInterval(this.pingTime); 
    }


    changeProviderStatus(data) {
        this.socket.emit('ChangeProviderStatus', { Status: data.status, UserId: data.UserId, ActionTime: data.ActionTime });
    }

    updateTelemedicineStatus(data) {
        this.socket.emit('UpdateStatus', { Status: data.Status, appointmentid: data.appointmentid });
    }

    disconnect() {
        this.socket.disconnect()
    }
    emitSocketEvent(event, data) {
        this.socket.emit(event, data)
    }


}





