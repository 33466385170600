import { Component, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import {GlobalService} from './services/data-service/global.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'InstantUCAdmin';
  constructor(public globalService:GlobalService, private router:Router,
    private cd:ChangeDetectorRef){
   
  }

  ngAfterViewInit(){
    this.cd.detectChanges();
  }

  ngOnInit(){

    setInterval(()=>{
      let token=localStorage.getItem("access_token")
      if(!token){
       this.router.navigateByUrl("/Auth")
      }
    },5000)
  }



 
}
